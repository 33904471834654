// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#nav-main').toggle();
  });
}

// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 576) {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      $(this).next().toggle();
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      event.stopPropagation();
      return false;
    });
    $('html').click(function() {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

function file_input() {
  $('.custom-file-input').each(function(index) {
    $(this).on("change", function(e) {
      var titleFile = e.target.files[0].name;
      $(this).next().find("span").text(titleFile)
    });
  })
}

function job_form(){
  $('.job-form').hide();
  $('.handle-job-form').on('click', function() {
    var type = $(this).attr('data-form');
    $("#job-type").val(type);
    if(type === 'stage') {
      $("#job-form-title").text('Vous souhaitez faire un stage chez nous ?')
      $("#job-form-desc").text('Votre stage chez Guez Ingenierie Guyane, envoyez-nous votre candidature via le formulaire suivant :')
    }else {
      $("#job-form-title").text('Une candidature à nous soumettre ?')
      $("#job-form-desc").text('Vous souhaitez rejoindre Guez Ingenierie Guyane, envoyez-nous votre candidature via le formulaire suivant :')
    }
    $('.job-form').show();
    $('html, body').animate({ scrollTop: $(document).height() }, 1000);
  })
}


// Initialisation
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
file_input();
job_form();

if ($('.number-wp').length) {
    $(window).scroll(testScroll);
}
var viewed = false;

function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

function testScroll() {
  if (isScrolledIntoView($(".number-wp")) && !viewed) {
      viewed = true;
      $('.number').each(function () {
          var value = $(this).text()
          $(this).prop('Counter',0).animate({
              Counter: parseInt($(this).data('value'), 10)
          }, {
              duration: 2000,
              easing: 'swing',
              step: function (now) {
                  $(this).text(Math.ceil(now));
              },
              complete: function (now) {
                  $(this).text(value)
              }
          });
    });
  }
}
